<template>
    <div class="carousel-container">
      <!-- 左右切换按钮 -->
     
  
      <!-- 图片展示区域 -->
      <div class="carousel-slides">
        <img
          v-for="(image, index) in images"
          :key="index"
          :src="image.src"
          :alt="image.alt"
          class="carousel-image"
          :class="{ active: index === currentIndex }"
        />
      </div>
  
      <!-- 指示器 -->
      <div class="carousel-indicators">
        <span
          v-for="(image, index) in images"
          :key="index"
          class="indicator-wrapper"
        >
          <span
            class="indicator"
            :class="{ active: index === currentIndex }"
            @click="goToSlide(index)"
          ></span>
          <span
            v-if="index < images.length - 1"
            class="connector"
          ></span>
        </span>
        
      </div>
    </div>
  </template>
  
  <script>
  import image1 from "../assets/图片11.jpg";
  import image2 from "../assets/图片13.jpg";
  import image3 from "../assets/图片15.jpg";
  import image4 from "../assets/图片16.jpg";
  import image5 from "../assets/图片17.jpg";
  export default {
    data() {
      return {
        currentIndex: 0, // 当前展示的图片索引
        images: [
          { src: image2, alt: "Image 2" },
          { src: image3, alt: "Image 3" },
          { src: image4, alt: "Image 4" },
          { src: image1, alt: "Image 1" },
          { src: image5, alt: "Image 5" },
        ],
      };
    },
    methods: {
      // 切换到上一张
      prevSlide() {
        this.currentIndex =
          this.currentIndex === 0 ? this.images.length - 1 : this.currentIndex - 1;
      },
      // 切换到下一张
      nextSlide() {
        this.currentIndex =
          this.currentIndex === this.images.length - 1 ? 0 : this.currentIndex + 1;
      },
      // 跳转到指定索引的图片
      goToSlide(index) {
        this.currentIndex = index;
      },
    },
  };
  </script>
  
  <style scoped>
  /* 主容器样式 */
  .carousel-container {
   margin-top: 600px;

    width: 900px;
    height: 600px;
    margin: 0 auto;
    overflow-y: hidden; /* 避免内部溢出导致滚动条 */
    background-color: #ffffff;
  }
  
  /* 图片容器样式 */
  .carousel-slides {
    display: flex;
    transition: transform 0.5s ease-in-out;
    height: 500px; /* 图片展示区域的高度 */
    width: 100%;
    position: relative;
    overflow-y: hidden;
    


  }
  
  .carousel-image {
    width: 100%;
    height: 100%;
    display: none;
    object-fit: cover;
  }
  
  .carousel-image.active {
    display: block;
  }
  
  /* 左右箭头样式 */
  .carousel-arrow {
    top: 192%;
    transform: translateY(-50%);
    background-color: rgb(0, 0, 0);
    color: white;
    font-size: 24px;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 50%;
    z-index: 2;
  }
  
  .carousel-arrow:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .left-arrow {
    left: 10px;
  }
  
  .right-arrow {
    right: 10px;
  }
  
  /* 指示器容器样式 */
  .carousel-indicators {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 30px; /* 距离图片底部的间距 */
    overflow: visible; /* 确保菱形指示器完整显示 */
    
  }
  
  /* 指示器样式 */
  .indicator-wrapper {
    display: flex;
    align-items: center;
  }
  
  .indicator {
    width: 13px; /* 缩小指示器尺寸 */
    height: 13px;
    background-color: #c0c0c0;
    transform: rotate(45deg); /* 旋转成菱形 */
    margin: 0 2px; /* 间隔调整为两侧 45px，总间隔为 90px */
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .indicator.active {
    background-color: #000000; /* 激活状态下为橙色 */
  }
  
  .indicator:hover {
    background-color: #000000; /* 悬停时为深灰色 */
  }
  
  /* 指示器之间的虚线连接 */
  .connector {
    width: 190px; /* 虚线宽度与间隔一致 */
    height: 2px;
    border-top: 2px dashed  #c0c0c0;
    margin: 0; /* 去除默认间距，确保连接 */
  }
  </style>
  