<template>
    <div class="main-6">
      <div class="about">
        <div class="about1"><h1>About</h1></div>
        <div class="about2">
          ——&nbsp;&nbsp;深圳硬盒交互设计科技有限公司是一家由科技极客和留学设计师组成的创意团队，
          专注于将互动科技应用于现实物理空间，创造数字化沉浸体验。自2019年成立以来，
          我们致力于将知识与文化转化为线下观众可以学习和体验的内容。我们已经在浙江、
          广州、湖南、上海、山西等地成功设计并落地了100多个项目，在数字文旅和教育信息化领域屡获行业好评。
          经过五年的积累，我们在博物馆展示技术和数字资源整合方面取得了显著进展，
          并在教育领域找到了持续发展的动力和方向。
        </div>
        <div class="about3"><button>></button></div>
      </div>
  
      <div class="time">
        <div class="time-item" v-for="(item, index) in arr" :key="index">
          <div style="display: flex;">
            <div class="time-num">
              <NumberCounter :targetNumber="item.num" :duration="3000" />
            </div>
            <div class="time1">
              {{ item.sym }}
            </div>
          </div>
          <div class="time-name">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import NumberCounter from './NumberCounter.vue';
  
  export default {
    name: "aboutWeb",
    components: {
      NumberCounter,
    },
    data() {
      return {
        arr: [
          { name: '公司成立', num:5, sym: "年" },
          { name: '合作伙伴', num:50, sym: "+" },
          { name: '位团队成员', num:15, sym: "+" },
          { name: '成功案例', num:100, sym: "+" },
        ],
      };
    },
    mounted() {
      // // 模拟数据加载完成后动态更新数字
      // setTimeout(() => {
      //   this.arr = [
      //     { name: '公司成立', num: 5, sym: "年" },
      //     { name: '合作伙伴', num: 50, sym: "+" },
      //     { name: '位团队成员', num: 15, sym: "+" },
      //     { name: '成功案例', num: 100, sym: "+" },
      //   ];
      // }, 500); // 延迟更新以触发动画
    },
  };
  </script>
  
  <style>
  .main-6 {
    width: 100%;
    height: 500px;
    background-color: rgb(234, 234, 234);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .about {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50%;
  }
  .about1 {
    width: 20%;
    margin-left: 8%;
    margin-top: 4%;
    font-size: 25px;
  }
  .about2 {
    width: 50%;
    margin-top: 8%;
    margin-left: -5%;
    color: #959595;
  }
  .about3 button {
    width: 60px;
    height: 60px;
    font-size: 20px;
    margin-top: 180%;
    margin-left: 380%;
  }
  .time {
    width: 100vw;
    display: flex;
    align-content: center;
    justify-content: space-around;
  }
  .time .time-item {
    padding-top: 20px;
  }
  .time-item .time-name {
    color: #333333;
    font-size: 16px;
    font-weight: normal;
    font-family: Arial;
    padding-top: 10px;
  }
  .time-item .time-num {
    color: #999999;
    font-family: Arial;
    font-size: 45px;
    font-weight: bold;
  }
  .time-item .time1 {
    color: #999999;
    font-size: 14px;
    font-weight: bold;
  }
  </style>
  