<template>
  <div class="main-3">
    <el-timeline :reverse="reverse">
      <el-timeline-item
        v-for="(activity, index) in activities"
        :key="index"
        :timestamp="activity.timestamp"
      >
        <div class="timeline-content">
          <img v-if="activity.image" :src="activity.image" class="timeline-image" alt="Activity Image" />
          <div class="text-content">
            <h2 class="title">{{ activity.title }}</h2>
            <p class="description">{{ activity.content }}</p>
          </div>
        </div>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
 
 

export default {
  data() {
    return {
      reverse: true,
      activities: [
        {
          title: "深圳",
          content: "硬盒交互注册成功",
          timestamp: "2019",
          
        },
        {
          title: "绍兴",
          content: "诗路展馆落地 全国第一批沉浸式文旅示范",
          timestamp: "2021",
        
        },
        {
          title: "深圳",
          content: "深大附小功能空间设计项目入选全国中华诗教示范教学案例",
          timestamp: "2021",
        
        },
        {
          title: "杭州",
          content: "入驻浙江省教育技术中心 参与领雁计划省级课题研发",
          timestamp: "2024",
        
        }
      ]
    };
  }
};
</script>

<style>
.main-3 {
  width: 100%;
  height: auto;
  background-color: rgb(255, 255, 255);
  padding-top: 60px;
  padding-left: 130px;
}

.timeline-content {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.text-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  font-size: 18px;
  margin-bottom: 3px;
  color: #333;
  margin-top: 10px;
}

.description {
  font-size: 14px;
  color: #666;
}

.el-timeline-item__wrapper {
  padding-left: 50px !important; /* 确保时间戳左对齐 */
}

.el-timeline-item__timestamp {
  position: absolute;
  left: -30px; 
  top: 0px;
  font-size: 13px;
  color: #666;
  transform: translateX(-100%); /* 确保完全对齐左侧 */
}
</style>

