<template>
    <div class="carousel">
        <div class="carousel-track" :style="trackStyle">
            <div class="carousel-item"><img src="../assets/展示1.png" alt=""></div>
            <div class="carousel-item"><img src="../assets/展示2.png" alt=""></div>
            <div class="carousel-item"><img src="../assets/展示3.png" alt=""></div>
            <div class="carousel-item"><img src="../assets/展示4.png" alt=""></div>
            <div class="carousel-item"><img src="../assets/展示5.png" alt=""></div>
        </div>
    </div>
</template>

<style>
.carousel {
    position: relative;
    width: 100%;
    max-width: 1000px; /* 可以调整此宽度 */
    margin: 0 auto;
    overflow: hidden;
    background-color: rgb(255, 255, 255);
}

.carousel-track {
    display: flex;
    transition: transform 1s ease; /* 设置过渡时间，使得滚动平滑 */
}

.carousel-item {
    min-width: 100%; /* 每张图片占容器的100% */
    box-sizing: border-box;
}

.carousel-item img {
    width: 100%;
    height: auto;
    object-fit: cover;
}
</style>

<script>
export default {
    data() {
        return {
            currentIndex: 0,
            interval: null, // 用于自动轮播的定时器
            totalItems: 5, // 图片总数量
            isTransitioning: false, // 判断是否正在过渡
        };
    },
    computed: {
        trackStyle() {
            return {
                transform: `translateX(-${this.currentIndex * 100}%)` // 每次滑动100%
            };
        }
    },
    methods: {
        next() {
            // 如果正在过渡中，直接返回，防止出现错乱
            if (this.isTransitioning) return;

            this.isTransitioning = true; // 开始过渡

            // 如果到达最后一张，直接跳到第一张
            if (this.currentIndex === this.totalItems - 1) {
                setTimeout(() => {
                    this.currentIndex = 0; // 快速跳转到第一张
                    this.isTransitioning = false; // 结束过渡
                }, 1000); // 延迟1秒钟后，回到第一张
            } else {
                this.currentIndex++; // 否则继续向下滚动
                this.isTransitioning = false; // 结束过渡
            }
        }
    },
    mounted() {
        // 每3秒自动切换
        this.interval = setInterval(this.next, 3000);
    },
    beforeDestroy() {
        // 在组件销毁时清除定时器，避免内存泄漏
        if (this.interval) {
            clearInterval(this.interval);
        }
    }
};
</script>
