<template>
  <div class="main-1">
    <span class="demonstration">Click 指示器触发</span>
    <el-carousel trigger="click" height="800px" arrow="always">
      <el-carousel-item v-for="(item, index) in images" :key="index">
        <img :src="item" alt="图片描述" class="carousel-image" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import image1 from "../assets/屏幕截图 2025-01-13 011418.jpg";
import image2 from "../assets/图片14.jpg";

export default {
  data() {
    return {
      images: [image1, image2]
    };
  }
};
</script>

<style>
.main-1 {
  /* 主容器样式 */
  width: 100%; /* 可根据需求调整 */
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 让图片按比例铺满 */
  display: block;
  margin: 0 auto;
}

.el-carousel__item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 800px; /* 保持与轮播高度一致 */
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
</style>
