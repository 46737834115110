<template>
  <div id="app">
    <indexWeb/>
  </div>
</template>

<script>
import indexWeb from './views/indexWeb.vue';

export default {
  name: 'App',
  components: {
    indexWeb
  }
}
</script>

<style>
#app {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #F8F8F8;
}
</style>
