<template>
  <div ref="counter" class="counter">{{ currentNumber }}</div>
</template>

<script>
export default {
  props: {
    targetNumber: {
      type: Number,
      required: true, // 目标数字
    },
    duration: {
      type: Number,
      default: 1000, // 整个动画持续时间（毫秒）
    },
  },
  data() {
    return {
      currentNumber: 0, // 当前显示的数字
      observer: null, // Intersection Observer
      hasStarted: false, // 是否已经开始递增
    };
  },
  mounted() {
    this.setupObserver();
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect(); // 销毁观察器
    }
  },
  methods: {
    // 设置 Intersection Observer
    setupObserver() {
      this.observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting && !this.hasStarted) {
            this.startIncrement(); // 开始递增
            this.hasStarted = true; // 标记已开始
            this.observer.disconnect(); // 一旦开始，停止观察
          }
        },
        { threshold: 0.5 } // 元素至少 50% 可见时触发
      );
      this.observer.observe(this.$refs.counter); // 观察当前组件的 DOM 节点
    },
    // 开始递增逻辑
    startIncrement() {
      let startTime = null; // 用于跟踪动画时间

      const increment = (timestamp) => {
        if (!startTime) startTime = timestamp; // 初始化动画开始时间

        const progress = timestamp - startTime; // 当前时间差

        // 计算递增到的数字
        this.currentNumber = Math.min(
          Math.round((this.targetNumber * progress) / this.duration),
          this.targetNumber
        );

        // 判断是否完成动画
        if (progress < this.duration) {
          requestAnimationFrame(increment); // 继续下一帧
        }
      };

      // 启动动画
      requestAnimationFrame(increment);
    },
  },
};
</script>

<style>
.counter {
  color: #999999;
  font-family: Arial;
  font-size: 45px;
  font-weight: bold;
}
</style>
