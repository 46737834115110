<template>
    <div class="head">
      <div class="logo"><img src="../assets/6a2b44979d144cd520e59261d6a3ca0e_a57353c51c426d498115b3acaaeb9882_8.jpg" alt=""></div>
      <div class="cont">
        <div class="en">
          <div style="margin-top: 30%;">EN&nbsp;&nbsp;&nbsp;</div>
          <div style="width: 1px; background-color:#606266;"></div>
        </div>
        <div class="el-icon-menu" @click="toggleMenu"></div>
      </div>
      <!-- 遮罩层 -->
      <div class="overlay" v-show="flag" @click="closeMenu"></div>
      <!-- 弹窗 -->
      <div class="set" v-show="flag" ref="popup">
         <!-- 关闭图标 -->
         <div class="el-icon-close close-icon" @click.stop="closeMenu"></div>
        
        <div
          class="aside-list-item"
          v-for="(item, index) in listArry"
          :key="index"
        >
          {{ item }}
         
        </div>
      </div>
    </div>
  </template>
  
  

  <script>
  export default {
    name: "headWeb",
    data() {
      return {
        flag: false, // 控制弹窗显示/隐藏
        listArry: ["首页", "项目展示", "新闻资讯", "业务范围", "关于我们", "联系我们"],
      };
    },
    methods: {
      toggleMenu() {
        this.flag = !this.flag; // 切换弹窗显示状态
      },
      closeMenu() {
        this.flag = false; // 关闭弹窗
      },
    },
  };
  </script>
  
  
<style>
.head {
  top: 0;
  width: 100%;
  height: 80px;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  z-index: 1000;

}

.logo {
  margin-left: 10%;
  width: 13%;
  /* overflow: hidden; 添加此行，隐藏溢出部分 */
  height: 100%; /* 确保高度和父级一致 */
  margin-top: -15px
}

.logo img {
  width: 100%;
  height: 100%; /* 保持图片比例 */
  object-fit: cover; /* 确保图片在容器内完整展示 */
}


.cont {
  padding-left:65%;
  display: flex;
}

.en {
  margin-left: -100%;
  color: #606266;
  display: flex;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
  z-index: 999; /* 在弹窗下面，头部上面 */
}

.el-icon-menu {
  font-size: 40px;
  margin-left: 20%;
  cursor: pointer;
  
}

.set {
  position: absolute;
  top: 0;
  left: 0;
  width: 25%;
  height: 100vh;
  background-color: white;
  z-index: 1001;
  cursor: pointer;
}

.aside-list-item {
  padding: 25px 0px 25px 140px;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #838383; 
  transition: color 0.3s, background-color 0.3s; /* 添加平滑过渡效果 */
}

/* 鼠标悬停时 */
.aside-list-item:hover {
  color: #000; /* 鼠标悬停时字体变黑 */
  background-color: #f2f2f2; /* 可选，增加背景变暗的效果 */
}

/* 关闭图标样式 */
.close-icon {
    display: block; /* 让图标独占一行 */
  width: 100%;    
  text-align: right; 
  font-size: 20px;
  color: #838383;
  cursor: pointer;
  transition: color 0.3s;
  padding: 10px 0; 
}

/* 鼠标悬停关闭图标 */
.close-icon:hover {
  color: #000;
}
.el-icon-close{
    font-size: 40px;
    float: right;
}
  </style>
  