<template>
  <div class="index">
    <div class="head">
        <headWeb></headWeb>
      </div>
  
      <div class="main">
        <div class="main-1"> <mainWeb></mainWeb></div>
         <div class="main-2"><photosWeb></photosWeb></div>
         <div class="main-3"><timeWeb></timeWeb></div>
         <div class="main-4"><picture-4></picture-4></div>
        <div class="main-5"><rollWeb></rollWeb></div>
        <div class="main-6"><AboutWeb></AboutWeb></div>
        <div class="main-7"><informWeb></informWeb></div>
      </div>
      <div class="btm">
        <div @click="toUrl('https://beian.miit.gov.cn/#/Integrated/index')">粤ICP备20008494号-1</div>
      </div>
  </div>
</template>

<script>
import headWeb from '@/components/headWeb.vue';
import mainWeb from '@/components/mainWeb.vue';
import photosWeb from '@/components/photosWeb.vue';
import timeWeb from '@/components/timeWeb.vue';
import picture4 from '@/components/picture-4.vue';
import rollWeb from '@/components/rollWeb.vue';
import AboutWeb from '@/components/aboutWeb.vue';
import informWeb from '@/components/informWeb.vue';
export default {
  name:"indexWeb",
  components:{
      headWeb,
      mainWeb,
      photosWeb,
      timeWeb,
      picture4,
      rollWeb,
      AboutWeb,
      informWeb

  },
  methods:{
    toUrl(url){
      window.open(url,"_blank")
    }
  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
}
.main{
  /* scroll-snap-type: y mandatory;  */
  overflow-x: hidden;
  background-color: #ffffff;
}
.btm{
  width: 100vw;
  height: 60px;
  background-color: #333333;
  text-align: center;
  line-height: 60px;
  color:#fff;
}
.btm a{
color: #fff;
text-decoration: none;
}
.btm a:hover{
  color: rgb(147, 147, 147);
}
</style>