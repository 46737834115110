<template>
    <div class="main-7">
        <div class="el-left"><img src="../assets/图片3.jpg" alt=""></div>
        <div class="el-right">
            <div class="num">联系我们</div>
            <div>电话:
                <br>
                <span>+86 15820273061</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span>+86 18682202801</span>
            </div>
            <div>邮箱：<br>
                <span>商务合作：yoogle@ongea.work</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span>简历投递：weijia@ongea.work</span>
            </div>
            <div>地址：<br>
                <span>杭州 杭州市拱墅区长乐路29号</span><br>
                <span>深圳 深圳市南山区留学生创业园 1期1508 </span><br>
                伦敦 Office 518, SPACES, 197-213 Jubilee house, Oxford Street W1D 2LF London
            </div><br>
            <div class="num1">
                <div>请填写您的姓名 <br><br> <input v-model="name" type="text">
                </div>
                <div>请填写您的手机<br><br> <input v-model="phone" type="text">
                </div>
            </div>
            <br>
            <div>
                请填写您的邮箱地址<br><br> <input v-model="email" type="text">
            </div>
            <br>
            <div>请填写留言信息 <br><br><textarea v-model="message"></textarea></div>
            <div class="but">
                <el-button @click="submitForm" type="info" round>提交信息</el-button> 
            </div>
        </div>

        <!-- 自定义弹窗 -->
        <div v-if="showPopup" class="popup-overlay">
            <div class="popup">
                <p>{{ popupMessage }}</p>
                <el-button @click="closePopup" type="primary" round>确认</el-button>
            </div>
        </div>
    </div>
</template>

<style>
.main-7 {
    width: 100%;
    height: 800px;
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
}
.el-left {
    width: 35%;
    height: 100%;
    margin-left: 10%;
}
.el-left img {
    width: 100%;
    height: 100%;
}
.el-right {
    display: flex;
    flex-direction: column;
    color: #959595;
    font-size: 15px;
    margin-left: 5%;
    margin-top: 3%;
    justify-content: space-around;
    width: 100%;
    height: 80%;
}
.num {
    font-weight: bold;
}
.num1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
}
input {
    border: none;
    border-bottom: 1px solid #959595;
    outline: none;
}
input:focus {
    border-bottom: 1px solid #959595;
}
textarea {
    width: 60%;
    height: 30px;
    border: none;
    border-bottom: 1px solid #959595;
    outline: none;
}
.but {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 50%;
}
button {
    width: 130px;
}

/* 自定义弹窗样式 */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.popup {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    text-align: center;
    width: 300px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.popup button {
    margin-top: 20px;
}
</style>

<script>
export default {
    data() {
        return {
            name: '',
            phone: '',
            email: '',
            message: '',
            showPopup: false, // 控制弹窗显示
            popupMessage: '' // 弹窗内容
        };
    },
    methods: {
        submitForm() {
            // 检查姓名、手机、邮箱、留言是否为空
            if (!this.name) {
                this.showAlert('请填写姓名');
                return;
            }
            if (!this.phone) {
                this.showAlert('请填写手机号');
                return;
            }
            if (!this.email) {
                this.showAlert('请填写邮箱地址');
                return;
            }
            if (!this.message) {
                this.showAlert('请填写留言信息');
                return;
            }

            // 检查手机号是否是11位并且以1开头
            const phonePattern = /^1\d{10}$/;
            if (!phonePattern.test(this.phone)) {
                this.showAlert('请输入有效的手机号码');
                return;
            }

            // 如果所有信息都填写了且手机号有效，弹出提交成功的提示
            this.showAlert('信息已提交成功！');
        },

        showAlert(message) {
            this.popupMessage = message;
            this.showPopup = true;
        },

        closePopup() {
            this.showPopup = false;
        }
    }
};
</script>
